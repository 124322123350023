import React, { useEffect, useState } from "react";
import styles from "../styles/DatePicker.module.css";
import { addDays, addMonths, differenceInMonths, format, isSameDay, lastDayOfMonth, startOfMonth } from "date-fns";
import ScrollContainer from "react-indiana-drag-scroll";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";

export default function DatePicker({
  endDate,
  selectDate,
  getSelectedDay,
  color,
  labelFormat
}) {
  const [selectedDate, setSelectedDate] = useState(null);

  const firstSection = {
    // marginLeft: '40px'
  };
  const corporateSecondaryColor = (window.location.href === 'https://weyzo-front.herokuapp.com/') ? '#00c2a3' : '#A5D663'
  const startDate = new Date();
  const lastDate = addDays(startDate, endDate || 90);
  const primaryColor = color || 'rgb(54, 105, 238)';
  const selectedStyle = {
    color: 'black',
    backgroundColor: 'white',
    borderColor: corporateSecondaryColor,
    borderWidth: 2,
    boxShadow: '0px 3px 20px 6px rgba(50, 50, 71, 0.1)'
  };
  const buttonColor = {
    background: primaryColor
  };
  const labelColor = {
    color: primaryColor
  };

  const getStyles = day => {
    if (isSameDay(day, selectedDate)) {
      return selectedStyle;
    }

    return null;
  };

  const getId = day => {
    if (isSameDay(day, selectedDate)) {
      return 'selected';
    } else {
      return "";
    }
  };

  function renderDays() {
    const dayFormat = "E";
    const dateFormat = "d";
    const months = [];
    let days = [];

    for (let i = 0; i <= differenceInMonths(lastDate, startDate); i++) {
      let start, end;
      const month = startOfMonth(addMonths(startDate, i));
      start = i === 0 ? Number(format(startDate, dateFormat)) - 1 : 0;
      end = i === differenceInMonths(lastDate, startDate) ? Number(format(lastDate, "d")) : Number(format(lastDayOfMonth(month), "d"));

      for (let j = start; j < end; j++) {
        if (new Date(addDays(month, j)).getDay() !== 0 && new Date(addDays(month, j)).getDay() !== 6) {
          days.push(React.createElement("div", {
            id: `${getId(addDays(startDate, j))}`,
            className: styles.dateDayItem,
            style: getStyles(addDays(month, j)),
            key: addDays(month, j),
            onClick: () => onDateClick(addDays(month, j))
          }, React.createElement("div", {
            className: styles.dayLabel
          }, format(addDays(month, j), dayFormat)), React.createElement("div", {
            className: styles.dateLabel
          }, format(addDays(month, j), dateFormat))));
        }
      }

      months.push(React.createElement("div", {
        className: styles.monthContainer,
        key: month
      }, React.createElement("span", {
        className: styles.monthYearLabel,
        style: labelColor
      }, format(month, labelFormat || "MMMM yyyy")), React.createElement("div", {
        className: styles.daysContainer,
        style: i === 0 ? firstSection : null
      }, days)));
      days = [];
    }

    return (
      <ScrollContainer className={styles.dateListScrollable}>
        { months }
      </ScrollContainer>
    )
  }

  const onDateClick = day => {
    setSelectedDate(day);

    if (getSelectedDay) {
      getSelectedDay(day);
    }
  };

  useEffect(() => {
    if (selectDate) {
      if (!isSameDay(selectedDate, selectDate)) {
        setSelectedDate(selectDate);
        setTimeout(() => {
          let view = document.getElementsByClassName('indiana-scroll-container')[0];

          if (view) {
            view.scrollIntoView({
              behavior: "smooth",
              inline: "center",
              block: "nearest"
            });
          }
        }, 20);
      }
    }
  }, [selectDate]);

  const nextWeek = () => {
    const e = document.getElementsByClassName('indiana-scroll-container')[0];
    const width = e ? e.getBoundingClientRect().width : null;
    e.scroll({ left: e.scrollLeft == 0 ? width - 54 : e.scrollLeft + width - 54, behavior: 'smooth' });
  };

  const prevWeek = () => {
    const e = document.getElementsByClassName('indiana-scroll-container')[0];
    const width = e ? e.getBoundingClientRect().width : null;
    e.scroll({ left: e.scrollLeft - width + 54, behavior: 'smooth' });
  };

  return (
      <>
        <div className={styles.container}>
            <div className={styles.buttonWrapper}>
                <button className={styles.button} style={buttonColor} onClick={prevWeek}>
                  <CaretLeftOutlined />
                </button>    
            </div>
            { renderDays() }
            <div className={styles.buttonWrapper}>
                <button className={styles.button} style={buttonColor} onClick={nextWeek}>
                  <CaretRightOutlined />
                </button>    
            </div>
        </div>
      </>
  )
}