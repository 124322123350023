const axios = require('axios');
const BASE_URL = process.env.REACT_APP_HOST_NAME;
const weyzoInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
})

// OBTAIN CSRF TOKEN FOR CALLS
const getCsrfToken = () => {
    return new Promise((resolve, reject) => {
        weyzoInstance.get('csrfToken')
        .then((result) => {
            resolve(result.data._csrf);
        }).catch((err) => {
            console.error('ERR CSRF', err);
            reject(err);
        });
    });
}

// CREATE LEAD FORM
export const createLead = (userName, userEmail, userPhone, position, country, idForm, userId) => {
    let leadData = {
        'name': userName, 
        'email': userEmail, 
        'phone': userPhone, 
        'user': userId,
        'position': position,
        'country': country,
        'form': idForm,
        '_csrf': null
    }

    return new Promise((resolve, reject) => {
        getCsrfToken().then(async (csrfToken) => {
            leadData._csrf = csrfToken;
            
            weyzoInstance.post('create_lead', leadData)
                .then((result) => {
                    resolve(result.data);
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                });
        }).catch((err) => console.error(err));
    });
}

// UPDATE LEAD FORM
export const updateLead = (leadId, userName, userEmail, userPhone, position, country, idForm) => {
    const leadData = {
        'id': leadId,
        'name': userName, 
        'email': userEmail, 
        'phone': userPhone, 
        'position': position, 
        'country': country, 
        'form': idForm,
        '_csrf': null
    }

    return new Promise((resolve, reject) => {
        getCsrfToken().then(async (csrfToken) => {
            leadData._csrf = csrfToken;
            weyzoInstance.post('update_lead', leadData)
                .then((result) => {
                    resolve(result.data);
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                });
        }).catch((err) => console.error(err));
    })
}

// GET ALL FORM DATA
export const getFormData = (formId) => {
    return new Promise((resolve, reject) => {
        weyzoInstance.get(`forms/show/${formId}`)
        .then((result) => {
            let data = result.data.formData;
            resolve(data);
        }).catch((err) => {
            console.error(err);
            reject(err);
        });
    });
}

// ANSWER A QUESTION
export const saveLeadAnswer = (leadId, formId, questionId, questionOptionId) => {
    let leadData = {
        'lead': leadId, 
        'form': formId, 
        'question': questionId, 
        'question_option': questionOptionId,
        '_csrf': null
    }

    return new Promise((resolve, reject) => {
        getCsrfToken().then(async (csrfToken) => {
            leadData._csrf = csrfToken;

            weyzoInstance.post('save_lead_answer', leadData)
                .then((result) => {
                    resolve(result.data);
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                });
        }).catch((err) => console.error(err));
    });
}

// UPDATE AN ANSWER OF A QUESTION
export const updateLeadAnswer = (leadAnswer, questionOptionId) => {
    let leadData = {
        'id': leadAnswer, 
        'question_option': questionOptionId,
        '_csrf': null
    }

    return new Promise((resolve, reject) => {
        getCsrfToken().then(async (csrfToken) => {
            leadData._csrf = csrfToken;

            weyzoInstance.post('update_lead_answer', leadData)
                .then((result) => {
                    resolve(result.data);
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                });
        }).catch((err) => console.error(err));
    });
}

export const getAllEvents = (userId) => {
    return new Promise((resolve, reject) => {
        weyzoInstance.get(`user_calendar/${userId}`)
            .then((res) => {
                resolve(res.data);
            }, (err) => {
                console.error(err);
                reject(err);
            });
    });
}

// CREATE EVENT WITH DATE SELECTED
export const createEvent = (date, description, userId, leadId) => {
    return new Promise((resolve, reject) => {
        const data = { date: date, description: description, user_id: userId, lead_id: leadId };
        resolve(data)
        weyzoInstance.post('create_event', JSON.stringify(data))
            .then((res) => {
                resolve(res.data.data);
            }, (err) => {
                console.error(err);
                reject(err);
            });
    });
}

// GET FORMID FROM THE DOMAIN
export const getByDomain = () => {
  return new Promise((resolve, reject) => {
    try {
      let domain = window.location.pathname;
      // Remove the first slash
      domain = domain.substring(1);
      // Remove the last slash
      domain = domain.substring(0, domain.length - 1);
      weyzoInstance.post("get_by_domain", { domain }).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          console.error(err);
          reject();
        }
      );
    } catch (err) {
      console.error(err);
      reject();
    }
  });
};

// GET FORMID FROM THE DOMAIN
export const getQualification = (leadId) => {
    return new Promise((resolve, reject) => {
        weyzoInstance.post('get_qualification', { lead_id: leadId })
            .then((res) => {
                resolve(res.data);
            }, (err) => {
                console.error(err);
                reject();
            })
    });
}

export const qualifyLead = (leadId, formId) => {
  let leadData = {
    'lead_id': leadId, 
    'form_id': formId,
    '_csrf': null
  }

  return new Promise((resolve, reject) => {
    getCsrfToken().then(async (csrfToken) => {
        leadData._csrf = csrfToken;

        weyzoInstance.post('qualify_lead', leadData)
        .then((res) => {
            resolve(res.data);
        }, (err) => {
            console.error(err);
            reject();
        })
    }).catch((err) => console.error(err));
  });
}

// GET USERS CALENDARS
export const getUsersCalendars = (formId) => {
    return new Promise((resolve, reject) => {
        let domain = window.location.pathname;
        // Remove the first slash
        domain = domain.substring(1);
        // Remove the last slash
        domain = domain.substring(0, domain.length - 1);
        weyzoInstance.post('getUsersCalendars', { domain })
            .then(res => {
              resolve(res.data)
            })
            .catch(err => {
              console.error(err)
              reject()
            })
    })
}
