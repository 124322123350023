import React, { createRef, useEffect, useRef, useState } from 'react';
import { scroller } from 'react-scroll';
import { animateScroll } from 'react-scroll/modules';
import { Card } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';

import { createLead, updateLead, createEvent, getByDomain, getUsersCalendars } from './api/weyzo.api';

import CustomSpinner from './components/custom-spinner';
import Watermark from './components/Watermark/Watermark';
import FormQuestions from './components/FormQuestions/FormQuestions';
import MeetingContactSoon from './components/MeetingContactSoon/MeetingContactSoon';
import MeetingSchedule from './components/MeetingSchedule/MeetingSchedule';
import MeetingConfirmation from './components/MeetingConfirmation/MeetingConfirmation';

import logoWeyzo from './assets/images/logo_weyzo.png';
import styles from './styles/App.module.css';

function App() {
  const containerStep = useRef();
  const answersSteps = useRef([]);

  // DEFINITIONS
  const [formState, setFormState] = useState({});
  const [userEmployee, setUserEmployee] = useState(null);
  const [createdLead, setCreatedLead] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const [eventDate, setEventDate] = useState(null);
  const [nonWorkingHours, setNonWorkingHours] = useState([]);
  const [disabledHours, setDisabledHours] = useState([]);
  const [eventHour, setEventHour] = useState(null);
  const [formScore, setFormScore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [totallyEvents, setTotallyEvents] = useState()
  const [usersFetched, setUsersFetched] = useState()
  const [fixedQuestionValues, setFixedQuestionValues] = useState({
    fullName: { value: '', error: false },
    workEmail: { value: '', error: false },
    jobTitle: { value: '', error: false },
    phone: { value: '', error: false },
    country: { value: '', error: false }
  });

  useEffect(() => {
    let interval = null;
    const hasValue = fixedQuestionValues && Object.values(fixedQuestionValues).some(item => item.value);

    if (hasValue) {
      interval = setInterval(() => {
        createUpdateLead();
        console.log(createdLead)
        clearInterval(interval);
      }, 600);
    }

    return() => {if (interval) clearInterval(interval)}
  }, [fixedQuestionValues]);

  useEffect(() => {
    if (formState.fixedQuestions && formState.fixedQuestions.length > 0) {
      const initialQuestionValues = formState.fixedQuestions.reduce((acc, question) => {
        acc[question.name] = { value: '', error: false };
        return acc;
      }, {});
  
      setFixedQuestionValues(initialQuestionValues);
    }
  }, [formState.fixedQuestions]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const form = await getByDomain();
        setUserEmployee(form.user)
        setFormState({
          id: form.id,
          user: form.user,
          title: form.title,
          subtitle: form.subtitle,
          primaryColor: form.primary_color,
          secondaryColor: form.secondary_color,
          buttonText: form.button_text,
          buttonBackground: form.button_background,
          buttonTextColor: form.button_text_color,
          legalText: form.legal_text,
          logoUrl: form.logo_url,
          videoCall: form.video_call,
          watermarkDisabled: form.watermark_disabled,
          fixedQuestions: form.fixed_questions,
          customFixedQuestions: form.custom_fixed_questions,
          qualifyingQuestions: form.qualifying_questions
        })
        getURLParams();
        getUsersCalendarsEffect(form.id);

        setAnswers(form.id, form.qualifying_questions);
        setIsLoading(false);
      } catch(err) {
        console.log(err)
        setShowToast(true);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setFormSteps();

    return() => {}
  }, [formStep]);

  const getUsersCalendarsEffect = async (formId) => {
    const hoursToBlock = await getUsersCalendars(formId)
    setDisabledHours(hoursToBlock.disabledHours)
    setTotallyEvents(hoursToBlock.totallyEvents)
    setUsersFetched(hoursToBlock.users)
    if(hoursToBlock.users.length === 1) {
      setUserEmployee(hoursToBlock.users[0])
    }
  }

  const setAnswers = (formId, formQualifyingQuestions) => {
    if (!formId) return;
    answersSteps.current = Array(formQualifyingQuestions.length).fill().map(element => createRef());
  }

  const setFormSteps = async () => {
    if (formStep > 0 && formStep < 2) {
      scroller.scrollTo('container-step', {
        delay: 0,
        duration: 800,
        smooth: 'linear'
      });
    } else if (formStep === 2 && formScore > 0) {
      await submitMeeting();
    }
  }

  const getURLParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const logo = queryParams.get('logo');
    const name = queryParams.get('name');
    const email = queryParams.get('email');
    const phone = queryParams.get('phone');

    if (logo !== null) {
      setFormState(prevState => ({ ...prevState, logoUrl: logo }));
    }
    if (name !== null) setFixedQuestionValues(prev => ({...prev, name: { value: name, error: false } }));
    if (email !== null) setFixedQuestionValues(prev => ({...prev, workEmail: { value: email, error: false } }));
    if (phone !== null) setFixedQuestionValues(prev => ({...prev, phone: { value: phone, error: false } }));
  }

  // CREATE AND UPDATE LEADS
  const createUpdateLead = async () => {
    let lead = {
      name: fixedQuestionValues.fullName?.value || '',
      email: fixedQuestionValues.workEmail?.value || '',
      phone: fixedQuestionValues.phone?.value || '',
      position: fixedQuestionValues.jobTitle?.value || '',
      country: fixedQuestionValues.country?.value || ''
    }

    if (!createdLead) {
      await createLead(lead.name, lead.email, lead.phone, lead.position, lead.country, formState.id, userEmployee.id)
        .then(async (lead) => {
          await setCreatedLead(lead);
        }).catch((err) => {
          console.error('CREATE LEAD ERROR', err);
        });
    } else {
      await updateLead(createdLead.id, lead.name, lead.email, lead.phone, lead.position, lead.country, formState.id)
        .then(async (lead) => {
          await setCreatedLead(lead);
        }).catch((err) => {
          console.error('UPDATE LEAD ERROR', err);
        })
    }
  }

  // SELECT DATE FROM CALENDAR
  const dateSelected = async (date) => {
    await setEventDate(date);

    var weekday = moment(date).weekday()
    var nwh = JSON.parse(userEmployee.non_working_hours)
    var hours = nwh[weekday-1].hours

    await setNonWorkingHours(hours)
    
    scroller.scrollTo('container-hours', {
      delay: 0,
      duration: 800,
      smooth: 'linear'
    });
    setEventHour(null);
  }

  // SELECT HOUR FROM THE LIST
  const selectHour = async (hour) => {
    await setEventHour(hour);
    const users = usersFetched.map(user => {
      const totalEv = totallyEvents.filter(ev => ev.userId === user.id).map(ev => ev.userTotalEv)[0]
      return {id: user.id, totalEv: totalEv ? totalEv : 0 }
    })
    const dateTime = moment(eventDate).format('DD-MM-YYYY')
    const availableDate = totallyEvents.filter((ev, key) => {
      return ev.date === dateTime
    })
    let userAvailable
    if(availableDate.length === 1) {
      users.sort((a,b) => a.totalEv - b.totalEv)
      const notAvailable = availableDate.filter(ev => ev.start === hour.hour)
      if(notAvailable.length > 0){
        userAvailable = users.filter(user => {
          return user.id !== notAvailable[0].userId
        })[0].id
      } else {
        users.sort((a,b) => a.totalEv - b.totalEv)
        userAvailable = users[0].id
      }
    } else if(availableDate.length === 0) {
      users.sort((a,b) => a.totalEv - b.totalEv)
      userAvailable = users[0].id
    } else {
      const notAvailable = availableDate.filter(ev => {
        return ev.start === hour.hour
      })
      if(notAvailable.length > 0){
        userAvailable = users.filter(user => {
          return user.id !== notAvailable[0].userId
        })[0].id
      } else {
        users.sort((a,b) => a.totalEv - b.totalEv)
        userAvailable = users[0].id
      }
    }
    setUserEmployee(usersFetched.filter(user => user.id === userAvailable)[0])
    
    animateScroll.scrollToBottom();
  }

  // SEND MEETING DATA TO API
  const submitMeeting = () => {
    const selectedDate = `${moment(eventDate).format('DD/MM/YYYY')} ${eventHour.hour}`;
    const date = moment(selectedDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ');

    createEvent(date, `Meeting with ${fixedQuestionValues.name.value} / ${fixedQuestionValues.email.value} / ${fixedQuestionValues.phone.value}`, userEmployee.id, createdLead.id)
    .then((data) => {
        console.log(data)
    }).catch((err) => console.error(err));
  }

  const corporateColor = '#8847E6'

  if (window.matchMedia("(max-width: 768px)").matches) {
    document.body.style.backgroundColor = '#FFFFFF';
  } else {
    document.body.style.backgroundColor = formState.secondaryColor || '#FCF9F0';
  }

  return (
    <>
      <Card
        bordered={false} 
        className={styles.formMainCard}>
        { showToast &&
          <div className={styles.platformNotAvailableContainer}>
            Platform not available at the moment
          </div> 
        }
        { isLoading && <CustomSpinner /> }
        { formStep === 0 &&
          <FormQuestions
            formId={formState.id}
            formTitle={formState.title}
            formSubtitle={formState.subtitle}
            formLegalText={formState.legalText}
            primaryColor={formState.primaryColor}
            formLogo={formState.logoUrl}
            enableVideoCall={formState.videoCall}
            buttonText={formState.buttonText}
            buttonBackgroundColor={formState.buttonBackground}
            buttonTextColor={formState.buttonTextColor}
            createdLead={createdLead}
            fixedQuestionValues={fixedQuestionValues}
            setFixedQuestionValues={setFixedQuestionValues}
            fixedQuestions={formState.fixedQuestions}
            customFixedQuestions={formState.customFixedQuestions}
            qualifyingQuestions={formState.qualifyingQuestions}
            answersSteps={answersSteps}
            setFormStep={setFormStep}
            setFormScore={setFormScore}
            setIsLoading={setIsLoading}
          />
        }
        { formStep === 1 && 
          <MeetingSchedule
            containerStep={containerStep}
            formLogo={formState.logoUrl}
            corporateColor={corporateColor}
            dateSelected={dateSelected}
            selectHour={selectHour}
            eventDate={eventDate}
            disabledHours={disabledHours}
            nonWorkingHours={nonWorkingHours}
            eventHour={eventHour}
            userEmployee={userEmployee}
            primaryColor={formState.primaryColor}
            setFormStep={setFormStep}
          />
        }
        { formStep === 2 &&
          <div>
            { formScore > 0 &&
              <MeetingConfirmation 
                corporateColor={corporateColor}
                userEmployeeImage={userEmployee.image}
                scheduleContainer={scheduleContainer}
                userEmployeeFullName={userEmployee.fullName}
                eventDate={eventDate}
                eventHour={eventHour}
              />
            }
            { formScore <= 0 &&
              <MeetingContactSoon color={formState.secondaryColor} />
            }
          </div>
        }
        { !formState.disabledWatermark && ( 
          <Watermark logo={logoWeyzo} />
        )}
      </Card>
    </>
  );
}

const scheduleContainer = {
  backgroundColor: 'white', 
  borderRadius: 10, 
  padding: 20,
  boxShadow: '0px 10px 16px rgba(20, 37, 63, 0.06)'
}

export default App;
