import React, { useEffect, useState } from "react";
import styles from "../styles/HourPicker.module.css";
import moment from "moment";
import { Skeleton } from "antd";
import { StopOutlined } from '@ant-design/icons';

export default function HourPicker({
  startHour, // FIRST HOUR FOR THE LIST
  endHour, // LAST HOUR FOR THE LIST
  dateSelected, // DATE SELECTED TO GET DISABLED HOURS
  stepsInMinutes, // ITERATIONS EACH MINUTES TO DISPLAY HOURS
  disabledHours, // DISABLED HOURS IN FORMAT [{ start: 'XX:XX', end: 'XX:XX' }]
  hiddenHours, // HIDE NON WORKING HOURS IN FORMAT ['XX:XX', 'XX:XX']
  defaultHour, // DEFAULT HOUR SELECTED, CAN BE NULL
  getHourSelected, // RETURN THE HOUR SELECTED TO THE PARENT
}) {
  const [availableHours, setAvailableHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState();
  const [todayDisabledHours, setTodayDisabledHours] = useState([]);

  useEffect(() => {
    setSelectedHour(defaultHour);
  }, [defaultHour]);

  useEffect(() => {
    const hoursList = [];

    setTodayDisabledHours([]);
    setAvailableHours([]);

    for (let i = 0; i < disabledHours.length; i++) {
      if (
        moment(disabledHours[i].date, "DD-MM-YYYY").isSame(moment(dateSelected))
      ) {
        hoursList.push(disabledHours[i]);
      }
    }

    setTodayDisabledHours(hoursList);
    console.log('availableHours', availableHours)
  }, [dateSelected]);

  useEffect(async () => {
    const hours = [];
    const start = new Date().setHours(startHour, 0, 0); // START WORKING HOUR
    const end = new Date().setHours(endHour, 0, 0); // END WORKING HOUR
    const difference = moment(end).diff(moment(start), "hours"); // DIFFERENCE BETWEEN START AND AND TIME
    const iterationTimes = (60 / stepsInMinutes) * difference; // ITERATION TIMES TO GET DESIRED HOURS PER STEPS IN MINUTES

    // SET ALL THE HOURS
    for (let i = 0; i < iterationTimes; i++) {
      if (i > 0) {
        await hours.push({
          id: i,
          hour: moment(hours[i - 1].hour, "HH:mm")
            .add(stepsInMinutes, "m")
            .format("HH:mm"),
          available: true,
          hidden: false,
        });
      } else {
        await hours.push({
          id: i,
          hour: moment(start).format("HH:mm"),
          available: true,
          hidden: false,
        });
      }
    }

    // MODIFY THE HOURS AVAILABILITY
    await todayDisabledHours.forEach((hour) => {
      hours.find((h, index) => {
        const isBetween = moment(h.hour, "HH:mm").isBetween(
          moment(hour.start, "HH:mm"),
          moment(hour.end, "HH:mm"),
          undefined,
          "[)"
        );
        if (isBetween) {
          hours[index].available = false;
        }
      });
    });

    if (hiddenHours.length > 0) {
      await hiddenHours.forEach((hour) => {
        hours.find((h, index) => {
          if (h.hour === hour) hours[index].hidden = true;
        });
      });
    }

    // SAVE THE HOURS
    await setAvailableHours(hours);
  }, [todayDisabledHours]);

  const selectHour = (hour) => {
    setSelectedHour(hour);
    getHourSelected(hour);
  };

  const hourCorporateColor =
    window.location.href === "https://weyzo-front.herokuapp.com/"
      ? styles.partnerHourSelected
      : styles.hourSelected;

  function renderHour(hour) {
    if (hour.hidden) {
      return null;
    }
  
    const hourClass = `${styles.hour} ${
      selectedHour === hour ? hourCorporateColor : null
    } ${!hour.available ? styles.hourDisabled : null}`;
  
    return (
      <div
        key={hour.id}
        onClick={() => selectHour(hour)}
        className={hourClass}
      >
        {hour.hour}
      </div>
    );
  }

  function renderNoAvailableHours() {
    return (
      <div className={styles.notAvailableHoursMessage}>
        <StopOutlined />
        <p>Not available hours for this date</p>
      </div>
    );
  }
  
  function renderSkeleton() {
    return new Array(9).fill(0).map((a, index) => (
      <div
        key={index}
        style={{
          minWidth: 110,
          maxWidth: 144,
          height: 40,
          flex: "0 1 32%",
        }}
      >
        <Skeleton.Button size={"default"} shape={"square"} block />
      </div>
    ));
  }

  const isAnyHourVisible = availableHours.some(hour => !hour.hidden);

  return (
    <div className={styles.hoursContainer}>
      { dateSelected 
        ? (isAnyHourVisible 
            ? availableHours.map(renderHour) 
            : renderNoAvailableHours())
        : renderSkeleton()}
    </div>
  );
}
