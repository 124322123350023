import React from 'react';
import { Element } from 'react-scroll';
import { Button } from 'antd';

import HourPicker from '../hour-picker';
import Meeting from '../meeting';
import DatePicker from '../date-picker';

import logoWeyzo from '../../assets/images/logo_weyzo.png';

const MeetingSchedule = (props) => {
  const { 
    containerStep, 
    formLogo, 
    corporateColor, 
    dateSelected, 
    selectHour, 
    eventDate, 
    disabledHours, 
    nonWorkingHours, 
    eventHour, 
    userEmployee, 
    primaryColor, 
    setFormStep 
  } = props;

  return (
    <Element name="container-step">
      <div ref={containerStep}>
        <div style={{width: '100%', textAlign: 'center', marginBottom: 20}}>
          { formLogo !== '' ? (
            <img src={formLogo} style={{width: '50%'}} alt="company logo" />
          ) : (
            <img src={logoWeyzo} style={{width: '50%'}} alt="company logo" />
          )}
        </div>
        
        <div style={{marginBottom: 40}}>
          <h2 style={{textAlign: 'center'}}>Schedule a Call | 30 minutes</h2>
          <h3 style={{textAlign: 'center', marginBottom: 20, color: corporateColor, fontSize: 20}}>What time works best for you?</h3>

          <DatePicker
            endDate={100}
            labelFormat={"MMMM"}
            getSelectedDay={date => dateSelected(date)}
            color={"#7A7585"}
          />
        </div>
        
        {/* <div ref={containerHours}> */}
        <Element name="container-hours">
          <HourPicker 
            getHourSelected={(hour) => selectHour(hour)}
            dateSelected={eventDate}
            startHour={0}
            endHour={24}
            stepsInMinutes={30}
            disabledHours={disabledHours}
            hiddenHours={nonWorkingHours}
            defaultHour={eventHour || null}
          />
        </Element>
        <div>
          <Meeting image={userEmployee.image || `https://ui-avatars.com/api/?name=${userEmployee.fullName?.replace(' ', '+')}&background=4ace67&color=fff`} endTime={30} eventDate={eventDate ? eventDate : null} eventHour={eventHour ? eventHour.hour : null} meetingName={userEmployee.fullName} />
          <Button 
            size='large'
            type="primary"
            style={{borderRadius: 8, border: 'none', fontWeight: '700', backgroundColor: primaryColor}}
            disabled={eventDate && eventHour ? false : true}
            onClick={ () => { setFormStep(2) } }
            block> 
            Book call 
          </Button>
        </div>
      </div>
    </Element>
  )
}

export default MeetingSchedule;
