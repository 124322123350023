import React from 'react';
import { Progress } from 'antd';
import '../App.css';

function CustomProgress({ label, percentage }) {
    const corporateSecondaryColor = (window.location.href === 'https://weyzo-front.herokuapp.com/') ? '#00c2a3' : '#A5D663'
    const getPorgressElements = document.getElementsByClassName('ant-progress-bg');
    if (getPorgressElements.length !== 0) {
      getPorgressElements[0].style.backgroundColor = corporateSecondaryColor;
    }

    return (
        <>
            <label style={labelStyle}>{label}</label>
            <Progress percent={percentage} status='active' showInfo={false} />
        </>
    )
}

const labelStyle = {
  fontWeight: 600,
  color: '#4A5568',
  fontSize: 15
}

export default CustomProgress;