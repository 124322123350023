import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import { Element, scroller } from 'react-scroll';
import { Button, Select } from 'antd';

import { saveLeadAnswer, updateLeadAnswer, qualifyLead } from '../../api/weyzo.api';

import CustomRadioGroup from '../custom-radiogroup';
import CustomInput from '../custom-input';
import CustomProgress from '../custom-progress';

import logoWeyzo from '../../assets/images/logo_weyzo.png';
import styles from './FormQuestions.module.css';
import countries from '../../utils/countries.json';

const FormQuestions = ({
  formId,
  formTitle,
  formSubtitle,
  formLegalText,
  primaryColor,
  formLogo,
  createdLead,
  enableVideoCall,
  buttonText,
  buttonBackgroundColor,
  buttonTextColor,
  fixedQuestionValues,
  setFixedQuestionValues,
  fixedQuestions,
  customFixedQuestions,
  qualifyingQuestions,
  answersSteps,
  setFormStep,
  setFormScore,
  setIsLoading
}) => {
  const { Option } = Select;
  const [displayQuestions, setDisplayQuestions] = useState(false);
  const [leadAnswers, setLeadAnswers] = useState([]);
  const recaptchaRef = React.createRef();

  const selectedItem = (ev, i) => {
    if (!leadAnswers[i]) {
      saveLeadAnswer(createdLead.id, formId, ev.question, ev.id)
        .then(async (leadAnswer) => {
          await setLeadAnswers(oldLeadAnswers => [...oldLeadAnswers, leadAnswer]);
        }).catch((err) => {
          console.error('ERROR SELECTING ANSWER', err);
        });
    } else {
      updateLeadAnswer(leadAnswers[i].id, ev.id)
        .then(async (modifiedLeadAnswer) => {
          await setLeadAnswers(oldLeadAnswers => {
            const modifiedLeadAnswers = oldLeadAnswers.map(ldAnswer => ldAnswer.id === modifiedLeadAnswer.id ? ldAnswer = modifiedLeadAnswer : ldAnswer);
            return modifiedLeadAnswers;
          });
        }).catch((err) => {
          console.error('ERROR UPDATE LEAD ANSWER', err);
        });
    }

    if (answersSteps.current[i+1]) {
      scroller.scrollTo(`question-${i+1}`, {
        delay: 0,
        duration: 800,
        smooth: 'linear',
        offset: -60
      });
    }
  }

  const checkQualify = () => {
    if (enableVideoCall === false) {
      setFormScore(0);
      setFormStep(2);
      return;
    }

    return qualifyLead(createdLead.id, formId).then(async (res) => {
      if (res.qualified === false) {
        await setFormScore(0);
        await setFormStep(2);
      } else {
        await setFormScore(1);
        await setFormStep(1);
      }
    }, (err) => {
      console.log(err)
    });
  }

  const isTextValid = (inputName) => {
    return new Promise(resolve => {
      setFixedQuestionValues(prevValues => {
        if (!prevValues[inputName]) {
          resolve(false);
          return prevValues;
        }

        const isError = prevValues[inputName].value === null || prevValues[inputName].value.trim() === '';
        resolve(isError);
        return {
          ...prevValues,
          [inputName]: { ...prevValues[inputName], error: isError }
        };
      });
    });
  };

  const isEmailValid = () => {
    return new Promise(resolve => {
      setFixedQuestionValues(prevValues => {
        if (!prevValues.workEmail) {
          resolve(false);
          return prevValues;
        }

        const regexEmail = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$";
        const isError = !new RegExp(regexEmail).test(prevValues.workEmail.value);
        resolve(isError);
        return {
          ...prevValues,
          workEmail: { ...prevValues.workEmail, error: isError }
        };
      });
    });
  };
  
  const isPhoneValid = () => {
    return new Promise(resolve => {
      setFixedQuestionValues(prevValues => {
        if (!prevValues.phone) {
          resolve(false);
          return prevValues;
        }

        const regexPhone = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$';
        const isError = !new RegExp(regexPhone).test(prevValues.phone.value);
        resolve(isError);
        return {
          ...prevValues,
          phone: { ...prevValues.phone, error: isError }
        };
      });
    });
  };

  const updateFixedQuestionValue = (field, event) => {
    setFixedQuestionValues(prevQuestions => ({
      ...prevQuestions,
      [field]: { value: event.target.value, error: false } 
    }));
  };

  const updateFixedQuestionValueSelect = (field, value) => {
    setFixedQuestionValues(prevQuestions => ({
      ...prevQuestions,
      [field]: { value: value, error: false }
    }));
  };

  const validateField = {
    fullName:   () => isTextValid('fullName'),
    workEmail:  isEmailValid,
    jobTitle:   () => isTextValid('jobTitle'),
    phone:      isPhoneValid,
    country:    () => isTextValid('country')
  }

  const checkInputFields = async () => {
    setIsLoading(true);
  
    const errors = await Promise.all([
      isTextValid('fullName'),
      isTextValid('jobTitle'),
      isEmailValid(),
      isPhoneValid(),
      isTextValid('country')
    ]);
  
    const hasError = errors.some(error => error);
    if (!hasError) {
      setDisplayQuestions(true);
      if (createdLead && answersSteps.current[0]) {
        scroller.scrollTo('questions-container', {
          delay: 0,
          duration: 800,
          smooth: 'linear'
        });
      }
    } else {
      setDisplayQuestions(false);
    }
  
    setIsLoading(false);
  };

  return (
    <div>
      <div style={{width: '100%', textAlign: 'center', marginBottom: 20}}>
        { formLogo !== '' ? (
          <img src={formLogo} style={{width: '50%'}} alt={formTitle} />
        ) : (
          <img src={logoWeyzo} style={{width: '50%'}} alt={formTitle} />
        )}
      </div>

      <div style={{marginBottom: 20, marginTop: 20, textAlign: 'center'}}>
        <h1 style={titleStyles}> { formTitle } </h1>
        <h2 style={subtitleStyles}> { formSubtitle } </h2>
      </div>
      <div>
        <div>
          {
            fixedQuestions && fixedQuestions.map((question, index) => (
              <div key={index} style={inputContainer}>
                { question.name === 'country' ? (
                  <>
                    <Select
                      placeholder="Select a Country"
                      style={customSelect}
                      className={fixedQuestionValues.country?.error ? 'errorSelect' : ''}
                      onChange={(value) => updateFixedQuestionValueSelect(question.name, value)}>
                      {
                        countries.map((country, index) => (
                          <Option key={index} value={country.name}>{country.mame}</Option>
                        ))
                      }
                    </Select>
                    <p style={alertStyle} hidden={!fixedQuestionValues.country?.error}>
                      Please enter a valid entry
                    </p>
                  </>
                ) : (
                  <CustomInput
                    key={question.id}
                    question={question}
                    onChange={(value) => updateFixedQuestionValue(question.name, value)}
                    isError={fixedQuestionValues[question.name]?.error}
                    changeEvent={validateField[question.name]}
                    data={fixedQuestionValues[question.name]?.value} />
                )}
              </div>
            ))
          }
          {
            customFixedQuestions && customFixedQuestions.map((question, index) => (
              <div key={index} style={inputContainer}>
                <CustomInput key={question.id} question={question} isError={''} />
              </div>
            ))
          }
        </div>
        { displayQuestions && 
          <Element name='questions-container'>
            <h2 style={{paddingTop: 20, fontFamily: 'RedHatDisplay-Bold'}}>
              Please, complete the following questions
            </h2>
            <div style={progressContainer}>
              <CustomProgress label={`Question ${leadAnswers.length}/${qualifyingQuestions.length}`} percentage={leadAnswers.length/qualifyingQuestions.length*100} />
            </div>
            {
              qualifyingQuestions && qualifyingQuestions
                .sort((a, b) => a.order - b.order)
                .map((question, index) => (
                <div key={index} className={styles.questionsContainer} ref={answersSteps.current[index]} name={`question-${index}`}>
                  <Element>
                    <CustomRadioGroup 
                      question={question} 
                      direction={'vertical'} 
                      defaultValue={null} 
                      onSelectItem={(ev) => selectedItem(ev, index)} 
                      disabled={index !== 0 && typeof leadAnswers[index-1] == 'undefined'} 
                    />
                  </Element>
                </div>
              ))
            }
          </Element>
        }
        <div style={{marginTop: 15}}>
          <Button
            type="primary" 
            style={{borderRadius: 8, border: 'none', fontWeight: '700', backgroundColor: buttonBackgroundColor, color: buttonTextColor}}
            disabled={displayQuestions && leadAnswers.length !== qualifyingQuestions.length} 
            onClick={() => displayQuestions ? recaptchaRef.current.execute() : checkInputFields()} 
            block
          >
            { buttonText }
          </Button>
        </div>
        { displayQuestions && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={() => checkQualify()}
          />
        )}
        { !displayQuestions && formLegalText &&
          <div style={{marginTop: 10}} dangerouslySetInnerHTML={{ __html: formLegalText }} />
        }
      </div>
    </div>
  )
}

const inputContainer = {
  marginBottom: 20
}

const progressContainer = {
  marginTop: 15,
  position: 'sticky',
  top: 0,
  backgroundColor: '#FFFFFF',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 15,
  paddingRight: 15,
  textAlign: 'center',
  marginLeft: -15,
  marginRight: -15,
  zIndex: 10
}

const customSelect = {
  width: '100%',
  borderRadius: 8,
}

const subtitleStyles = {
  fontSize: 18,
}

const titleStyles = {
  fontSize: 26,
  marginBottom: 0,
}

const alertStyle = {
  color: "#d32f2f",
  paddingTop: 3,
  paddingBottom: 3,
};

export default FormQuestions;
